//const origin = window.location.hostname;
//const origin = "192.168.0.36";
const origin = "192.168.0.242";
//const originport = "80";
const originport = "5000";
// const dash = "http://" + window.location.hostname + ":5050";
const online = window.location.origin;

export const onlineUrl = online + "/api";
//Function Remark
export const offlineUrl = "http://" + origin + ":" + originport +"/api";
//export const offlineUrl = onlineUrl;
//export const offlineUrlExpress = "http://" + origin + ":5500";
export const offlineUrlExpress = "https://fstvisiondevapi.fs-technology.com";
