import { Spin } from "antd";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const convertToCrop = (
  topX: number,
  topY: number,
  bottomX: number,
  bottomY: number,
  w: number,
  h: number
) => {
  return {
    x: topX * w,
    y: topY * h,
    width: (bottomX - topX) * w,
    height: (bottomY - topY) * h,
  };
};

interface DetectLabelElement {
  topLeftX: number;
  topLeftY: number;
  bottomRightX: number;
  bottomRightY: number;
}

const convertToPosition = (
  x: number,
  y: number,
  width: number,
  height: number,
  w: number,
  h: number
): DetectLabelElement => {
  return {
    topLeftX: Number((x / w).toFixed(2)),
    topLeftY: Number((y / h).toFixed(2)),
    bottomRightX: Number(((x + width) / w).toFixed(2)),
    bottomRightY: Number(((y + height) / h).toFixed(2)),
  };
};

export interface DatasetDemoCropProps {
  imageUrl: string;
  displayImageRef: HTMLImageElement;
  position: any;
  setPositionValue: (params: DetectLabelElement) => void;
}
export interface cropProps {
  x: number;
  y: number;
  width: number;
  height: number;
}

const DatasetDemoCrop: React.FC<DatasetDemoCropProps> = ({
  imageUrl,
  displayImageRef,
  position,
  setPositionValue,
}) => {
  const [imageWidthAndHeight, setImageWidthAndHeight] = useState<
    [number, number]
  >([0, 0]);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const [loadingImage, setLoadingImage] = useState(false);

  const [src, setSrc] = useState<string>("");
  const [crop, setCrop] = useState<cropProps>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const onCropComplete = (crop: any) => {
    const [w, h] = imageWidthAndHeight;
    const { x, y, width, height } = crop;
    let position = convertToPosition(x, y, width, height, w, h);
    setPositionValue({ ...position });
  };

  const onCropChange = (crop: any) => {
    setCrop({ ...crop });
  };

  useEffect(() => {
    const { topLeftX, topLeftY, bottomRightX, bottomRightY } = position;
    const [w, h] = imageWidthAndHeight;
    const cropPosition = convertToCrop(
      topLeftX,
      topLeftY,
      bottomRightX,
      bottomRightY,
      w,
      h
    );
    setCrop({ ...cropPosition });
  }, [position, imageWidthAndHeight]);

  const loadImage = async (url: string) => {
    const maxWidth = 600;
    const maxHeight = 600;
    const img = new Image();
    img.src = url;
    img.crossOrigin = "Anonymous";
    img.onload = () => {
      if (canvasRef && canvasRef.current) {
        const canvas = canvasRef.current;
        const { naturalWidth, naturalHeight } = displayImageRef;
        const rh = maxHeight;
        const rw = rh * (naturalWidth / naturalHeight);
        const w = rw > maxWidth ? maxWidth : rw;
        const h = rh * (w / rw);
        setImageWidthAndHeight([w, h]);
        canvas.width = w;
        canvas.height = h;
        const context = canvas.getContext("2d");
        context!.clearRect(0, 0, 1000, 1000);
        context!.drawImage(img, 0, 0, w, h);
        let dataURL = canvas.toDataURL("image/png");
        setSrc(dataURL);
        setLoadingImage(false);
      }
    };
  };

  useLayoutEffect(() => {
    setLoadingImage(true);
    loadImage(imageUrl);
  }, [imageUrl, displayImageRef]);

  return (
    <div style={{ marginLeft: 50 }}>
      <canvas
        style={{
          display: "none",
        }}
        ref={canvasRef}
      ></canvas>
      {loadingImage ? (
        <div>
          <Spin />
        </div>
      ) : (
        <ReactCrop
          keepSelection
          src={src}
          crop={crop}
          onComplete={onCropComplete}
          onChange={onCropChange}
        />
      )}
    </div>
  );
};

export default DatasetDemoCrop;
